import { ImagePreview } from './image-preview';
import { DocumentPreview } from './document-previewer';
import React from 'react';

const isImage = (file: string) => {
  const ext = file.split('.').pop() || '';
  return ['png', 'jpg', 'jpeg', 'gif','webp','svg'].indexOf(ext) !== -1;
};

export const FilePreview = ({
  file,
  onDelete,
  assetUrl,
}: {
  file: string;
  onDelete: (file: string) => void;
  assetUrl?: string;
}) => {
  return isImage(file) ? (
    <ImagePreview
      file={assetUrl ? `${assetUrl}/${file}` : file}
      onDelete={onDelete}
    />
  ) : (
    <DocumentPreview
      file={assetUrl ? `${assetUrl}/${file}` : file}
      onDelete={onDelete}
    />
  );
};
