export enum UserStatus {
  Init = "Init",
  Enable = "Enable",
  Disable = "Disable",
}

export enum UserRole {
  Owner = "Owner",
  Maintainer = "Maintainer",
}

export enum CmsStatus {
  Draft = "Draft",
  Pending = "Pending",
  Approved = "Approved",
  NeedReview = "Need Review",
  Rejected = "Rejected",
  Retract = "Retract",
  Scheduled = "Scheduled",
  Publish = "Published",
}

export enum PageType {
  Article = "Article",
  Video = "Video",
  Gallery = "Gallery",
  Blog = "Blog",
  Page = "Page",
}

export enum Documents {
  Aadhar = "Aadhar",
  Pan = "Pan",
  VoterId = "VoterId",
  Electricity = "Electricity",
}
