import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddCategoryModal from "./CreateCategory";
import { getCategoryList, updateCategory } from "../../Services/Service";
import EditCategoryPage from "./EditCategoryPage";
import { onFailure } from "../../util/helper";
import { ActionInterface } from "./../../Components/BasicTable";
import { FaEdit } from "react-icons/fa";
import SortableTree, {
  getFlatDataFromTree,
  getTreeFromFlatData,
} from "react-sortable-tree";
//@ts-ignore

import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";

const ManageCategoryPage = () => {
  const [data, setData] = React.useState<any>([]);
  const [openModel, setopenModel] = useState(false);
  const [openEditModel, setopenEditModel] = useState(false);
  const [editValue, setEditValue] = useState({});

  const getKey = (node: any) => node._id;
  const getParentKey = (node: any) => (node.parent ? node.parent : null);

  const onSuccess = (data: any) => {
    const catData = data.data.map((cat: any) => ({
      ...cat,
      title: cat.name,
      expanded: true,
      parent: cat.parent ? cat.parent : null,
    }));

    const treeData = getTreeFromFlatData({
      flatData: catData,
      getKey: getKey,
      getParentKey,
      //@ts-ignore
      rootKey: null,
    });
    setData(treeData);

    // setData(data.data);
  };
  const handleCreateModelOpen = () => {
    setopenModel(true);
  };
  const handleCreateModelClose = () => {
    setopenModel(false);
  };

  React.useEffect(() => {
    getCategoryList(onSuccess, onFailure);
  }, []);

  const handleOpenEditModel = (data: any) => {
    setopenEditModel(true);
    setEditValue(data);
  };

  const handleEditModelClose = () => {
    setopenEditModel(false);
  };

  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => handleOpenEditModel(data),
    },
    {
      text: "Disable",
      trigger: (data: any) => {
        const status = {
          id: data.id,
          status: "Disable",
        };
        updateCategory(status, onSuccess, onFailure);
      },
    },
  ];

  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Manage Category</h1>
          </div>
          <Button className="btn btn-success" onClick={handleCreateModelOpen}>
            Add Category
          </Button>
        </div>
        {/*
        <List
          data={data.list}
          dataKey={"name"}
          actionItem={actionItems}
          showIcon={true}
          iconKey={"image"}
          staticPath={ASSET_URL}
        /> */}
        <div style={{  }}>
          <SortableTree
            //@ts-ignore
            treeData={data}
            canDrag={false}
            style={{ height: "calc(100vh - 90px)" }}
            rowHeight={60}
            innerStyle={{padding: 20}}
            onChange={() => ""}
            theme={FileExplorerTheme}
            generateNodeProps={(rowInfo) => ({
              buttons: [
                <button
                  className="btn btn-link"
                  onClick={() => {
                    handleOpenEditModel(rowInfo.node);
                  }}
                >
                  <FaEdit />
                </button>,
              ],
            })}
          />
        </div>
      </main>
      {openModel && (
        <AddCategoryModal
          show={openModel}
          setopenModel={setopenModel}
          setCategoryData={onSuccess}
          handleClose={handleCreateModelClose}
          category={data}
        />
      )}
      {openEditModel && (
        <EditCategoryPage
          show={openEditModel}
          setopenModel={setopenEditModel}
          setCategoryData={onSuccess}
          editValue={editValue}
          category={data}
          handleClose={handleEditModelClose}
        />
      )}
    </>
  );
};
export default ManageCategoryPage;
