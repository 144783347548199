import axios from "axios";

const instance = axios.create();

instance.interceptors.response.use(
  (successRes) => successRes,
  ({ response }) => {
    // console.log(response);
    if (response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return Promise.reject(response);
  }
);

export const client = instance;
