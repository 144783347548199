import { useState, useEffect } from "react";
import { useField } from "formik";
import { ErrorMessage } from "../ErrorMessage";
// @ts-ignore
import Files from "react-butterfiles";
import {
  IFileToUpload,
  IFileUploadError,
  IFileUploadProps,
} from "./file-uploader.types";
import { FilePreviewContainer } from "./file-upload.styled";
import { FileUploading } from "./file-uploading";
import { FileError } from "./file-error";
import { Label } from "../Label";
import { FilePreview } from "./file-preview";
import { Input } from "../input/input";
import { Button } from "react-bootstrap";

export const FileUpload = ({
  label,
  required,
  name,
  upload,
  maxSize,
  accept,
  assetUrl,
  tag
}: // type,
IFileUploadProps) => {
  const [field, meta, helpers] = useField({ name });
  const [imageUrl, setImageUrl] = useState<any>();
  const [tagName, setTageName] = useState();
  // TODO Use field arrays
  // const multiple = type === 'multiple';
  useEffect(() => {
    if (field.value && typeof field.value === "object") {
      setImageUrl(field.value.key);
      helpers.setValue(field.value._id);
      setTageName(meta?.initialValue?.tag)
    }
  }, [field.value]);

  const [state, setState] = useState<{
    files: IFileToUpload[];
    errors: IFileUploadError[];
  }>({
    files: [],
    errors: [],
  });

  const uploadFiles = () => {
    Promise.all(
      state.files.map((fileToUpload) => {
        return upload(
          { file: fileToUpload.src.file, tag: tagName },
          {
            onUploadProgress: (event: any) => {
              const { loaded, total } = event;
              fileToUpload.uploadPercent = Math.floor(loaded / total) * 100;
              setState({ files: state.files, errors: [] });
            },
          }
        );
      })
    ).then((responses) => {
      const keys = responses.map((response) => {
        return response.data.data._id;
      });
      const urls = responses.map((response) => {
        return response.data.data.key;
      });
      setState({ files: [], errors: [] });
      setImageUrl(urls[0]);
      // if (multiple) {
      //   helpers.setValue([...field.value, ...keys]);
      // } else {
      helpers.setValue(keys[0]);
      // }
    });
    const fileToUpload = state.files[0];
    fileToUpload.isUploading = true;
  };
  const onSuccess = (files: IFileToUpload[]) => {
    setState({ files: [...state.files, ...files], errors: [] });
  };

  const handleDelete = (value: string) => {
    helpers.setValue(
      undefined
      // multiple ? field.value.filter((v: string) => v !== value) : undefined
    );
  };

  const onError = (errors: IFileUploadError[]) => {
    setState({ files: state.files, errors: errors });
  };

  const handleCloseError = (error: IFileUploadError) => {
    setState({
      files: state.files,
      errors: state.errors.filter((e) => e.id !== error.id),
    });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-5">
          <Files
            // multiple={multiple}
            maxSize={maxSize}
            accept={accept}
            onSuccess={(files: any) => onSuccess(files)}
            onError={(errors: any) => onError(errors)}
          >
            {({ browseFiles }: any) => (
              <>
                <Label label={label} required={required} />

                <div className="custom-file">
                  <label
                    htmlFor="upload"
                    onClick={browseFiles}
                    className="custom-file-label"
                  >
                    Upload File Here
                  </label>
                </div>
                <ErrorMessage meta={meta} />
              </>
            )}
          </Files>
        </div>
        {tag && (

          <div className="col-md-5">
            <Input
              name="tag"
              value={tagName}
              label="Alt Tag"
              onChange={(e: any) => {
                setTageName(e.target.value);
              }}
            ></Input>
          </div>
        )}
        <div className="col-md-2 mt-4">
          <Button onClick={uploadFiles}>Upload</Button>
        </div>
      </div>

      <FilePreviewContainer>
        {/* {(multiple ? field.value : field.value ? [field.value] : []).map( */}
        {imageUrl && (
          <FilePreview
            key={field.value}
            file={imageUrl}
            assetUrl={assetUrl}
            onDelete={handleDelete}
          />
        )}

        {state.files.map((file) => (
          <FileUploading file={file} key={file.id} />
        ))}

        {state.errors.map((error) => (
          <FileError error={error} onClose={handleCloseError} key={error.id} />
        ))}
      </FilePreviewContainer>
    </>
  );
};

export default FileUpload;
