import { Accordion, Button, Form } from "react-bootstrap";
import React from "react";
import { Form as UiFrom } from "./../../../Components/Form";
import {
  addMenu,
  getCategoryList,
  getMenu,
  getMenuList,
  updateMenu,
} from "../../../Services/Service";
import Swal from "sweetalert2";
import { onFailure } from "./../../../util/helper";
import { BackButton, Select } from "./../../../Components";
import { useParams } from "react-router-dom";
// import SortableTree from "react-sortable-tree";
import SortableTree, {
  getFlatDataFromTree,
  getTreeFromFlatData,
} from "react-sortable-tree";
//@ts-ignore
import FileExplorerTheme from "react-sortable-tree-theme-minimal";
import omit from "lodash/omit";
import { MENU_OPTIONS } from "../../../constant";

enum menuTypeEnum {
  Blog = "Blog",
  Category = "Category",
  Page = "Page",
}

interface menuItem {
  itemId: string;
  name: string;
  url: string;
  position?: number;
  menuType: menuTypeEnum;
}
interface menuInterface {
  name: string;
  items: menuItem[];
  active?: boolean;
}

const EditMenu = () => {
  const [category, setCategory] = React.useState<any>([]);
  const [catTreeData, setCatTreeData] = React.useState<any>([]);
  const [menuData, setMenuData] = React.useState<any>([]);
  const [menuTreeData, setMenuTreeData] = React.useState<any>([]);
  const [initialValue, setInitialValue] = React.useState<menuInterface>({
    name: "",
    items: [],
  });

  const categorySuccess = (data: any) => {
    // setCategory(data.data);
    const catData = data.data?.map((cat: any) => ({
      ...cat,
      url: cat.slug,
      itemId: cat._id,
      title: cat.name,
      menuType: "Category",
      expanded: true,
      // parent: cat.parent && cat.parent.length > 0 ? cat.parent[0] : null,
      parent: cat.parent ? cat.parent : null,
    }));
    setCategory(catData);
    // setFlatData(flatData)
  };

  const menuListSuccess = (data: any) => {
    if (data.data) {
      setInitialValue(data.data);
      // const menuData = data.data.items.map((menu: any) => ({
      //   ...menu,
      //   title: menu.name,
      //   menuType: "Category",
      //   expanded: true,
      //   parent: menu.parent ? menu.parent : null,
      // }));
      // setMenuData(menuData);
      // setMenuTreeData(data.data.items);
      const getNodeKey = (node: any) => node.itemId;
      const menuData = getFlatDataFromTree({
        treeData: data.data.items,
        getNodeKey,
      }).map((flatData) => omit(flatData.node, "children"));
      // console.log("menuData", menuData);
      setMenuData(menuData);
    }
  };

  const menuSuccess = (data: any) => {
    Swal.fire({
      title: "Menu Updated Successfully",
      icon: "success",
    });
    getMenuList(menuListSuccess, onFailure);
  };

  let { id }: any = useParams();

  React.useEffect(() => {
    if (id) {
      getMenu(menuListSuccess, onFailure, id);
    }
    getCategoryList(categorySuccess, onFailure);
  }, []);

  const getKey = (node: any) => node._id;
  const getParentKey = (node: any) => node.parent;

  const getMenuKey = (node: any) => node.itemId;

  React.useEffect(() => {
    if (menuData.length > 0) {
      // console.log("update menu tree");
      const menuTreeData = getTreeFromFlatData({
        flatData: menuData,
        getKey: getMenuKey,
        getParentKey,
        //@ts-ignore
        rootKey: null,
      });
      // console.log("new menu data", menuData);
      // console.log("new menu tree", menuTreeData);
      setMenuTreeData(menuTreeData);
    }
  }, [menuData]);

  React.useEffect(() => {
    if (category.length > 0) {
      // console.log("category", category);
      const catTreeData = getTreeFromFlatData({
        flatData: category,
        getKey,
        getParentKey,
        //@ts-ignore
        rootKey: null,
      });
      setCatTreeData(catTreeData);
    }
  }, [category]);

  const isItemInMenu = (id: string) =>
    menuData.some((menu: menuItem) => menu.itemId === id);

  const getItemInfo = (id: string) =>
    category.filter((p: any) => p._id === id)[0];

  const removeItemFromMenu = (id: string) => {
    const newMenu = menuData.filter(
      (menuItem: menuItem) => menuItem.itemId !== id
    );
    setMenuData(newMenu);
  };

  const addItemToMenu = (item: any, values: any) => {
    const isChecked = item.target.checked;
    const [menuType, itemId] = item.target.value.split(":");
    const itemInfo: any = getItemInfo(itemId);
    // const menuItems = values.items;
    if (isChecked) {
      //check if item in menu then add item to menu
      if (!isItemInMenu(itemId)) {
        setMenuData([
          ...menuData,
          {
            itemId,
            title: itemInfo.name,
            url: itemInfo.url,
            parent: itemInfo.parent,
            expanded: true,
            menuType,
          },
        ]);
      }
    } else {
      //check if not there, if present remove it
      if (isItemInMenu(itemId)) {
        removeItemFromMenu(itemId);
      }
    }
  };

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />

        <UiFrom
          initialValues={initialValue}
          onSubmit={(values: any) => {
            const payload = {
              ...values,
              items: menuTreeData,
            };
            // console.log(payload);
            if (id) {
              updateMenu(payload, menuSuccess, onFailure);
            } else {
              addMenu(payload, menuSuccess, onFailure);
            }
          }}
          render={({ handleChange, values }: any) => {
            return (
              <>
                <div className="row">
                  <div className="col-md-4">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Categories</Accordion.Header>
                        <Accordion.Body>
                          {catTreeData &&
                            catTreeData.map((cat: any) => (
                              <div>
                                <Form.Check
                                  type="checkbox"
                                  label={cat.name}
                                  value={`${menuTypeEnum.Category}:${cat._id}`}
                                  checked={isItemInMenu(cat._id)}
                                  onClick={(event) => addItemToMenu(event, cat)}
                                />
                                {cat?.children &&
                                  cat.children.map((child: any) => (
                                    <div style={{ paddingLeft: "1rem" }}>
                                      <Form.Check
                                        type="checkbox"
                                        label={child.name}
                                        value={`${menuTypeEnum.Category}:${child._id}`}
                                        checked={isItemInMenu(child._id)}
                                        onClick={(event) =>
                                          addItemToMenu(event, child)
                                        }
                                      />
                                    </div>
                                  ))}
                              </div>
                            ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="col-md-8 tile">
                    <div className="row">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="row p-2">
                            <div className="col-md-3">
                              <h4>Menu Name</h4>
                            </div>
                            <div className="col-md-7">
                            <Select
                                options={MENU_OPTIONS}
                                id="template"
                                name="name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div style={{ height: "60vh" }}>
                          {menuTreeData && (
                            <SortableTree
                              //@ts-ignore
                              treeData={menuTreeData}
                              onChange={(treeData: any) => {
                                // console.log(treeData);
                                setMenuTreeData(treeData);
                              }}
                              theme={FileExplorerTheme}
                            />
                          )}
                        </div>
                        <div className="d-flex justify-content-end uvs p-2">
                          <Button className="btn btn-success" type="submit">
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        />
      </main>
    </>
  );
};
export default EditMenu;
