import React from 'react';
import styled, { css } from 'styled-components';

const getPageRange = (
  currentPage: number,
  offset: number,
  totalPages: number
) => {
  const maxButtonsCount = offset * 2 + 1;
  const firstButtonValue = currentPage - offset;
  const withinPageRange = (page: number) => page > 1 && page <= totalPages - 1;
  return Array(maxButtonsCount)
    .fill(0)
    .map((_, idx) =>
      firstButtonValue <= 1
        ? Math.max(firstButtonValue, 2) + idx
        : Math.min(firstButtonValue, totalPages - maxButtonsCount) + idx
    )
    .filter(withinPageRange);
};

export interface PaginatorTypes {
  onPageChange: (page: number) => void;
  totalPages: number;
  page: number;
  offset?: number;
}

export interface PageButtonType {
  value: number;
  active: boolean;
  onClick: (a: number) => void;
}

export class Paginator extends React.Component<PaginatorTypes> {
  setPage = (page: number) => {
    this.props.onPageChange(page);
  };

  render() {
    const { page, totalPages, offset = 1 } = this.props;

    if (!totalPages || totalPages <= 1) {
      return null;
    }

    const pageRange = getPageRange(page, offset, totalPages);

    return (
      <Pagination role="navigation" aria-label="Pagination Navigation">
        <PageIndicator
          id={`previousButton`}
          onClick={() => this.setPage(page - 1)}
          disabled={page <= 1}
          aria-label={'Previous'}
        >
          {'Previous'}
        </PageIndicator>
        <PageButton
          key={'first-page'}
          value={1}
          active={page === 1}
          onClick={this.setPage}
        />

        {pageRange[0] > 2 && <Ellipsis>...</Ellipsis>}

        {pageRange.map(n => (
          <PageButton
            active={n === page}
            key={'page' + n}
            value={n}
            onClick={this.setPage}
          />
        ))}

        {pageRange[pageRange.length - 1] < totalPages - 1 && (
          <Ellipsis>...</Ellipsis>
        )}

        <PageButton
          key={'last-page'}
          value={totalPages}
          active={page === totalPages}
          onClick={this.setPage}
        />
      </Pagination>
    );
  }
}

export const Ellipsis = styled.span`
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  font-size: 2.2rem;
  height: 30px;
`;

export const PageButton = ({ value, active, onClick }: PageButtonType) => {
  return (
    <PageIndicator active={active} onClick={() => onClick(value)}>
      {value}
    </PageIndicator>
  );
};

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const PageIndicator: any = styled.button`
  cursor: pointer;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: 2px;
  margin: 2px;
  height: 30px;

  ${({ active }: any) =>
    active &&
    css`
      background-color: #c0c0c0;
      text-decoration: underline;
    `};
`;
