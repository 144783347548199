import styled from 'styled-components';

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Button = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: none;
`;

export const ImageContainer = styled.div`
  max-width: 100px;
  height: 70px;
  margin-right: 5px;
  margin-top: 5px;
  flex: 0 0 auto;
  position: relative;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d0d7dd;
  &:hover ${Button} {
    display: block;
  }
`;

export const FilePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const UploadingContainer = styled.div`
  box-shadow: 0 3px 3px -2px rgba(39, 44, 51, 0.1),
    0 3px 4px 0 rgba(39, 44, 51, 0.04), 0 1px 8px 0 rgba(39, 44, 51, 0.02);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  position: relative;
  display: flex;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #dfe2e6;
  border-radius: 0.5rem;
  min-width: 200px;
  padding: 1rem;
  align-items: center;
  flex-direction: row;
  margin-right: 12pt;
  margin-top: 12pt;
`;

export const IconButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: white;
  position: absolute;
  right: 0px;
  top: 0px;
  &:hover {
    cursor: pointer;
  }
`;
