import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SMALL_PAGE_SIZE } from "../../../constant";
import { Create_Client, Edit_Client } from "../../../RouteConstant";
import { getClientList } from "../../../Services/Service";
import { onFailure } from "../../../util/helper";
import { PaginatedTable, Paginator } from "../../../Components";
import { ActionInterface } from "../../../Components/BasicTable";

const ManageClient = () => {
  const [data, setData] = React.useState({ list: [], total: 0 });
  const history = useHistory();
  const [currentPage, setPage] = useState(1);
  // const listParams = { populate: "user_f=name" };

  const onSuccess = (data: any) => {
    setData(data.data);
  };

  React.useEffect(() => {
    getClientList(onSuccess, onFailure);
  }, []);

  const headings = [
    {
      key: "name",
      text: "Name",
    },
    {
      key: "email",
      text: "Email",
    },
    {
      key: "mobile",
      text: "Mobile",
    },
  ];

  const pageChange = (page: any) => {
    getClientList(onSuccess, onFailure, { page: page });
    setPage(page);
  };

  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) =>
        history.push(Edit_Client.replace(":id", data._id)),
    },
  ];
  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Client Management</h1>
          </div>
          <Button
            onClick={() => history.push(Create_Client)}
            className="btn btn-success"
          >
            Add Client
          </Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <PaginatedTable
                  headings={headings}
                  data={data.list}
                  totalSize={data.total}
                  pageSize={SMALL_PAGE_SIZE}
                  actionItems={actionItems}
                  renderPager={() => (
                    <Paginator
                      totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                      onPageChange={(page) => pageChange(page)}
                      page={currentPage}
                    />
                  )}
                ></PaginatedTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default ManageClient;
