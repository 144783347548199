import React from 'react';
import styled from 'styled-components';
import BasicTable, { ActionInterface } from './BasicTable';
import { StyledDataPager } from './DataPager';

export interface paginationType {
  title?: string;
  headings?: any;
  data: any;
  pageSize: number;
  totalSize: number;
  displayHeader?: boolean;
  onClick?: () => void;
  renderPager?: any;
  onPageChange?: (a: any, b: any) => void;
  actionItems?: ActionInterface[];
  showActionDrawer?: boolean;
  formatters?: any;
}

export const TableFooter = styled.div`
  display: flex;
  justify-content: 'flex-end';
  margin: 4px 0;
`;
export class PaginatedTable extends React.Component<paginationType> {
  state = {
    displayedData: [],
  };

  setDisplayedData = (data: any) => {
    this.setState({ displayedData: data });
  };

  render() {
    const {
      title,
      headings,
      data,
      displayHeader = true,
      totalSize,
      onClick,
      renderPager,
      actionItems,
      showActionDrawer,
      formatters,
    } = this.props;
    const { displayedData } = this.state;

    if (!headings || !data) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <BasicTable
            title={title}
            headings={headings}
            showActionDrawer={showActionDrawer}
            data={renderPager ? data : displayedData}
            displayHeader={displayHeader}
            onClick={onClick}
            actionItems={actionItems}
            formatters={formatters}
          />
          <TableFooter>
            {renderPager ? (
              renderPager()
            ) : (
              <StyledDataPager
                data={data}
                totalSize={totalSize}
                onChange={this.setDisplayedData}
                pageSize={this.props.pageSize}
              />
            )}
          </TableFooter>
        </div>
      </div>
    );
  }
}

export default PaginatedTable;
