import React from "react";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { uploadPublic } from "../../../Services/FileUploadService";
import { Field, FieldArray, FieldProps } from "formik";
import {
  createCms,
  getCms,
  getUserList,
  updateCms,
} from "../../../Services/Service";
import { HTML, MetaTags } from "../../../interfaces";
import { ASSET_URL, HTML_FIELDS } from "../../../constant";
import { onFailure } from "../../../util/helper";
import Swal from "sweetalert2";
import { BackButton } from "../../../Components/BackButton";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Select } from "../../../Components/Select";
import omit from "lodash/omit";
import {
  Form,
  Input,
  FileUpload,
  DateTime,
} from "../../../Components";
import { Article_Management } from "../../../RouteConstant";
import { RichTextEditor } from "../../../Components/rich-text-editor/rich-text-editor";
// import mammoth from 'mammoth';
export interface ArticleFormValues {
  title?: string;
  seo?: {
    title?: string;
    description?: string;
    metaTags?: MetaTags[];
  };
  body: HTML[];
  publishDate?: Date;
  permaLink?: string;
  isFeatured?: boolean;
}

export const CreateArticle = () => {
  let { id }: any = useParams();
  const history = useHistory();
  // const [categoryOption, setCategoryOptions] = useState([]);
  const [users, setUsers] = useState([]);
  // const [html, setHtml] = useState(null);
  const [initialValues, setInitialValues] = useState<ArticleFormValues>({
    title: "",
    seo: {
      title: "",
      description: "",
    },
    body: [HTML_FIELDS],
    publishDate: new Date(),
    permaLink: "",
    isFeatured: false,
  });
  const onGetSuccess = (res: any) => {
    const data: any = omit(res.data, [
      "views",
      "createdAt",
      "updatedAt",
      "slug",
    ]);
    setInitialValues(data);
  };

  useEffect(() => {
    if (id) {
      const params = {
        populate: "image",
      };
      getCms(id, onGetSuccess, onFailure, params);
    }
    getUserList(userSuccess, onFailure, { all: true });
  }, []);

  const userSuccess = (data: any) => {
    const options = data.data.list.map((user: any) => ({
      label: user.name,
      value: user._id,
    }));
    setUsers(options);
  };

  const onSuccess = (res: any) => {
    Swal.fire({
      title: id ? "Article Updated Successfully" : "Article Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Article_Management);
    });
  };
  const onSubmit = (values: any, status: any, isPreview = false) => {
    let payload: any = {};
    if (id) {
      payload._id = id;
    }
    if (values?._id) {
      payload._id = values._id;
    }
    // if(html != null){
    //  const body = [{
    //   title:'',
    //   body:html
    //   }]
    //   payload.body = body;
    // }else{
    payload.body = values.body;

    // }
    payload.image = values.image;
    payload.description = values.description;
    payload.publishDate = values.publishDate;
    payload.seo = values.seo;
    payload.title = values.title;
    payload.user = values.user;
    payload.status = status;
    if (values.canonicalUrl) {
      payload.canonicalUrl = values.canonicalUrl;
    }
    if (values.permaLink) {
      payload.permaLink = values.permaLink;
    }
    if (values.imageTitle) {
      payload.imageTitle = values.imageTitle;
    }

    if (payload?._id) {
      updateCms(payload, onSuccess, onFailure);
    } else {
      createCms(payload, onSuccess, onFailure);
    }
  };

  // const handleFileUpload = (event:any) => {

  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = async (e:any) => {
  //     const arrayBuffer = e.target.result;
  //     const result = await convertToHTML(arrayBuffer);

  //     setInitialValues({
  //       body: [{
  //         title: "",
  //         body: result,
  //       }],
  //     })

  //   };
  //   if(file && file != null){

  //     reader.readAsArrayBuffer(file);
  //   }
  // };
  // const handlePDFFileUpload = (event:any, setFieldValue:any) => {
  //     const formData = new FormData();
  //     formData.append(
  //         "file",
  //         event.target.files[0]
  //     );
  //   getPdfHtml(formData,(res:any) => onSuccessPdf(res, setFieldValue), onFailure)
  // };

  // const onSuccessPdf = (res:any, setFieldValue:any)=>{
  //   // const parser = new DOMParser();
  //   // const doc = parser.parseFromString(res.text, 'text/html');
  //   // const bodyElement = doc.querySelector('body');
  //   setHtml(res.text)
  //   setFieldValue('fieldArray','body',[{ title: "", body: res.text}])
  //   setInitialValues({
  //     body: [{
  //       title: "",
  //       body: res.text,
  //     }],
  //   })
  // }
  // const convertToHTML = async (arrayBuffer:any) => {
  //   const options = {
  //     arrayBuffer: arrayBuffer,
  //   };
  //   const result = await mammoth.convertToHtml(options);
  //   const html = result.value; // Extracted HTML content
  //   return html;
  // };
  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="tile">
          <div>
            <div className="app-title">
              {id && <h1>Update Article</h1>}
              {!id && <h1>Add Article</h1>}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  initialValues={initialValues}
                  render={({ handleChange, values, setFieldValue }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row ">
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="title"
                                    label="TITLE"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                {/* <div className="col-md-12 p-2">
                                  <Select
                                    isMulti={true}
                                    name="categories"
                                    id="categories"
                                    options={categoryOption}
                                    label="Select Cateogries"
                                  />
                                </div> */}
                                <div className="col-md-12 p-2">
                                  <Select
                                    name="user"
                                    id="user"
                                    options={users}
                                    label="Select Author"
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`description`}
                                    label="Short Description"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`canonicalUrl`}
                                    label="Canonical Url"
                                    onChange={handleChange}
                                    // required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <div className="row">
                                    <div className="col-md-9">
                                      <div className="form-group">
                                        <Input
                                          type="text"
                                          name={`permaLink`}
                                          label="Permanent Url"
                                          onChange={(event: any) => {
                                            setFieldValue(
                                              "permaLink",
                                              event.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>

                                    {values?.title ? (
                                      <div className="col-md-3 mb-3">
                                        <div
                                          style={{
                                            marginTop: 30,
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            onClick={(e) => {
                                              setFieldValue(
                                                "permaLink",
                                                `${values?.title
                                                  .trim()
                                                  .toLowerCase()
                                                  .replace(/[\s`’']/g, "-")
                                                  .replace(/[^a-z0-9-]/g, "")}`
                                              );
                                            }}
                                          >
                                            Make Permalink Url
                                          </Button>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.title"
                                    label="SEO TITLE"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.description"
                                    label="SEO DESCRIPTION"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    name="seo.metaTags[0].content"
                                    id="seo.metaTags[0].content"
                                    label="Keywords"
                                    maxLength="500"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <FieldArray name="body">
                                {({ remove, push }) => (
                                  <div className="row ">
                                    <h5>SECTION</h5>
                                    {values?.body?.length > 0 &&
                                      values.body.map(
                                        (value: any, index: number) => (
                                          <div
                                            key={index}
                                            className="page-section mt-2 position-relative"
                                          >
                                            <div className="col-md-12 p-2">
                                              <Input
                                                type="text"
                                                name={`body.${index}.title`}
                                                label="Sub-Headline"
                                                onChange={handleChange}
                                              />
                                            </div>
                                            <div className="col-md-12 p-2">
                                              {/* <Label label="Content" /> */}
                                              <Field
                                                name={`body.${index}.body`}
                                                render={({
                                                  form,
                                                  field,
                                                }: FieldProps<
                                                  number | string
                                                >) => {
                                                  return (
                                                    <RichTextEditor
                                                      id={`body.${index}.body`}
                                                      name={`body.${index}.body`}
                                                      label="Content"
                                                      required
                                                      maxHeight={600}
                                                      uploadFile={uploadPublic}
                                                      getAssetUrl={ASSET_URL}
                                                    />
                                                  );
                                                }}
                                              />
                                            </div>
                                            {index > 0 && (
                                              <div className="icon-button text-danger">
                                                <MdDelete
                                                  onClick={() => remove(index)}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                    <div className="d-flex justify-content-end uvs p-2">
                                      <Button
                                        size="lg"
                                        variant="success"
                                        onClick={() => push({})}
                                      >
                                        <AiOutlinePlus />
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                              <div className="row">
                                <div className="col-md-12 p-2">
                                  <FileUpload
                                    accept={[
                                      "image/png",
                                      "image/jpeg",
                                      "image/webp",
                                      "image/svg",
                                    ]}
                                    type="single"
                                    maxSize={"3mb"}
                                    name={"image"}
                                    upload={uploadPublic}
                                    assetUrl={ASSET_URL}
                                    label="Cover Image"
                                    required
                                    tag={true}
                                  />
                                  <Input
                                    type="text"
                                    name="imageTitle"
                                    label="Cover Image Title"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 p-2">
                                  <DateTime
                                    name={`publishDate`}
                                    id={`publishDate`}
                                    label="Publish Date"
                                    required
                                    showTimeSelect={true}
                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                  />
                                </div>
                              </div>
                              {/* <div className="row">
                                <div className="col-md-2 p-2">
                                  <CheckBox
                                    name="inpress"
                                    id="inpress"
                                    label="IN PRESS"
                                  />
                                </div>
                                <div className="col-md-2 p-2">
                                  <CheckBox
                                    name="isSponsored"
                                    id="isSponsored"
                                    label="Sponsored Article"
                                  />
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end uvs p-2">
                          <Button
                            className="btn btn-success mr-2"
                            type="button"
                            onClick={() => window.history.go(-1)}
                          >
                            <AiFillCheckCircle />
                            Cancel
                          </Button>
                          {/* <Button
                            className="btn btn-success mr-2"
                            type="submit"
                            onClick={() => onSubmit(values, "Draft", true)}
                          >
                            <AiFillCheckCircle />
                            Preview
                          </Button> */}
                          <Button
                            className="btn btn-success mr-2"
                            type="submit"
                            onClick={() =>
                              onSubmit(values, id ? "Draft" : "Draft")
                            }
                          >
                            <AiFillCheckCircle />
                            Save
                          </Button>
                          {/* {id ? (
                            <Button
                              className="btn btn-success"
                              type="submit"
                              onClick={() => onSubmit(values, "Retract")}
                            >
                              <AiFillCheckCircle />
                              Retract
                            </Button>
                          ) : ( */}
                          <Button
                            className="btn btn-success"
                            type="submit"
                            onClick={() => onSubmit(values, "Published")}
                          >
                            <AiFillCheckCircle />
                            Publish
                          </Button>
                          {/* )} */}
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
