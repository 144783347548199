import { useState } from "react";
import { Header } from "./Header";
import { Menu } from "./Menu";

export const Layout = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={`app sidebar-mini ${collapsed ? "sidenav-toggled" : ""}`}>
      <Header setCollapsed={setCollapsed} collapsed={collapsed} />
      <Menu />
      {children}
    </div>
  );
};
