import { ActionInterface } from "./BasicTable";
import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";

interface ActionTypes {
  dataItem?: any;
  actionItems?: ActionInterface[];
}

export const ActionDrawer = ({ actionItems, dataItem }: ActionTypes) =>
  actionItems ? (
    <div className="d-flex align-middle">
      <Dropdown>
        <Dropdown.Toggle className="action-dropdown">
          <FaEllipsisV className="icon" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {actionItems.map((item: ActionInterface) => (
            <Dropdown.Item
              onClick={() => item.trigger(dataItem)}
              key={item.text}
            >
              {item.hasOwnProperty("conditionalText")
                ? item.conditionalText(dataItem)
                : item.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  ) : (
    <></>
  );
