import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../constant";

export const UserToken = () => localStorage.getItem("token");

export const config = () => ({
  headers: {
    Authorization: `Bearer ${UserToken()}`,
  },
});

const createForm = (data: any) => {
  const formData = new FormData();
  formData.append("file", data.file);
  if (data.name) {
    formData.append("name", data.name);
  }
  if (data?.tag) {
    formData.append("tag", data.tag);
  }
  return formData;
};

export const uploadPublic = (data: any, fileConfig: AxiosRequestConfig ={}) => {
  const formData = createForm(data);
  return axios.post(`${BASE_URL}/asset/upload`, formData, {
    ...fileConfig,
    ...config(),
  });
};

export const uploadPrivate = (data: any, fileConfig: AxiosRequestConfig) => {
  const formData = createForm(data);
  return axios.post(`${BASE_URL}/asset/upload/private`, formData, {
    ...fileConfig,
    ...config(),
  });
};
