import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { Manage_Redirect_url } from "../../RouteConstant";
import { getRedirectUrl, updateRedirectUrl } from "../../Services/Service";
import Swal from "sweetalert2";
import { ErrorBlock, BackButton, Form, Input } from "../../Components";
import { validationSchema } from "./validation";
import { onFailure } from "../../util/helper";

const EditRedirectUrl = () => {
  let { id }: any = useParams();
  const [error, setError] = useState();
  const history = useHistory();
  const [initialValues, setInitialValue] = useState({});
  useEffect(() => {
    getRedirectUrl(id, onGetSuccess, onFailure);
  }, [id]);
  const onGetSuccess = (res: any) => {
    setInitialValue(res.data);
  };
  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Redirection Updated Successfully",
      icon: "success",
    }).then(() => {
      history.push(Manage_Redirect_url);
    });
  };

  const userFailure = (error: any) => {
    if (error.status == 400) {
      setError(error.data.message.msg.split(":")[1]);
    }
  };

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="tile">
              <div className="w-100">
                <h3 className="text-center">Edit Redirection Url</h3>
                {error && <ErrorBlock>{error}</ErrorBlock>}

                <Form
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values: any) => {
                    updateRedirectUrl(values, onSuccess, userFailure);
                  }}
                  render={({ handleChange }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="oldUrl">Old Url*</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    name="oldUrl"
                                    type="text"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="redirectUrl">
                                    Redirect Url*
                                  </label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="text"
                                    name="redirectUrl"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end">
                          <Button className="btn btn-primary " type="submit">
                            <AiFillCheckCircle />
                            Save
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default EditRedirectUrl;
