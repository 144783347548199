import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export interface subMenuInterface {
  path: string;
  title: string;
  icon: any;
}
export interface MenuInterface {
  path: string;
  title: string;
  icon: any;
  subMenu?: subMenuInterface[];
}

export interface MenuItemInterface {
  menuItems: MenuInterface[];
}

const ArrowContainer = styled.div`
  position: absolute;
  right: 5px;
`;

const MenuButton = styled(Button)`
  border-radius: 0;
  background: white;
  width: 100%;
  text-align: left;
  text-decoration: none;
  box-shadow: none !important;
`;

export const MenuItem = ({ menuItem }: any) => {
  const [subMenu, setSubMenu] = React.useState(false);
  const showSubMenu = () => setSubMenu(!subMenu);
  return (
    <li>
      {menuItem.subMenu ? (
        <MenuButton
          // component="button"
          className="app-menu__item"
          variant="link"
          // sx={{ width: "100%", textDecoration: "none", display: "relative" }}
          onClick={showSubMenu}
        >
          {menuItem.icon}
          <span className="app-menu__label">{menuItem.title}</span>
          <ArrowContainer>
            {subMenu ? <FaAngleDown /> : <FaAngleRight />}
          </ArrowContainer>
        </MenuButton>
      ) : (
        <NavLink className="app-menu__item " exact to={menuItem.path}>
          {menuItem.icon}
          <span className="app-menu__label">{menuItem.title}</span>
        </NavLink>
      )}
      {subMenu &&
        menuItem.subMenu &&
        menuItem.subMenu.map((item: any, index: any) => (
          <NavLink
            className="app-menu__item submenu"
            to={item.path}
            key={index}
          >
            {item.icon}
            <span className="app-menu__label">{item.title}</span>
          </NavLink>
        ))}
    </li>
  );
};

export const Sidebar = ({ menuItems }: MenuItemInterface) => {
  return (
    <>
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <ul className="app-menu">
          {menuItems.map((menuItem: MenuInterface, index: Number) => (
            <MenuItem menuItem={menuItem} key={index} />
          ))}
        </ul>
      </aside>
    </>
  );
};
