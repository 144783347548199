import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { Manage_User } from "../../RouteConstant";
import { createUser, getRoleList, getUser } from "../../Services/Service";
import { UserSchema } from "../../util/ValidationSchema";
import Swal from "sweetalert2";
import { onFailure } from "../../util/helper";
import { ErrorBlock, BackButton, Form, Input, Select, FileUpload } from "../../Components";
import { uploadPublic } from "../../Services/FileUploadService";
import { ASSET_URL } from "../../constant";

const CreateUserPage = () => {
  const [roleOption, setRoleOptions] = useState([]);

  const [error, setError] = useState();

  const [initialValues, setInitialValue] = useState({});

  const history = useHistory();

  const roleSuccess = (data: any) => {
    const options = data.data.list.map((role: any) => ({
      label: role.name,
      value: role._id,
    }));
    setRoleOptions(options);
  };

  useEffect(() => {
    getRoleList(roleSuccess, onFailure, { status: "Enable" });
  }, []);

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "User Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Manage_User);
    });
  };

  const userFailure = (error: any) => {
    if (error.status == 400) {
      setError(error.data.message.msg.split(":")[1]);
    }
  };

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="tile">
              <div className="w-100">
                <h3 className="text-center">Create User</h3>
                {error && <ErrorBlock>{error}</ErrorBlock>}

                <Form
                  initialValues={initialValues}
                  validationSchema={UserSchema}
                  onSubmit={(values: any) => {
                    createUser(values, onSuccess, userFailure);
                  }}
                  render={({ handleChange }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="email">Email</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="fname">Name</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="phone">Phone</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="tel"
                                    name="phone"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="phone">Select Roles</label>
                                </div>
                                <div className="col-md-10">
                                  <Select
                                    isMulti={true}
                                    name="roles"
                                    id="roles"
                                    options={roleOption}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-12 p-2">
                                  <FileUpload
                                    accept={["image/png", "image/jpeg","image/webp","image/svg"]}
                                    type="single"
                                    maxSize={"3mb"}
                                    name={"image"}
                                    upload={uploadPublic}
                                    assetUrl={ASSET_URL}
                                    label="Image"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="password">Password</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="confirmPassword">
                                    Confirm Password
                                  </label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="password"
                                    name="confirmPassword"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end">
                          <Button className="btn btn-primary " type="submit">
                            <AiFillCheckCircle />
                            Save
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default CreateUserPage;
