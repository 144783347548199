import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { uploadPublic } from "../../../Services/FileUploadService";
import { ASSET_URL } from "../../../constant";
import { BackButton } from "../../../Components/BackButton";
import { onFailure } from "../../../util/helper";
import {
  createEpaper,
  getEpaper,
  updateEpaper,
} from "../../../Services/Service";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Input, FileUpload, DateTime } from "../../../Components";
import omit from "lodash/omit";
import { Epaper_Management } from "../../../RouteConstant";
import moment from "moment";
export interface PageFormValues {
  title: string;
}

const CreateEpaper = () => {
  let { id }: any = useParams();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState<PageFormValues>({
    title: "",
  });

  const onGetSuccess = (res: any) => {
    const data: any = omit(res.data, [
      "views",
      "createdAt",
      "updatedAt",
      "slug",
    ]);
    setInitialValues(res.data);
  };

  useEffect(() => {
    if (id) {
      getEpaper(id, onGetSuccess, onFailure);
    }
  }, []);

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Epaper Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Epaper_Management);
    });;
  };
  return (
    <>
      <main className="app-content" id="app">
        <BackButton />

        <div className="tile">
          <div>
            <div className="app-title">
              {id && <h1>Update Epaper</h1>}
              {!id && <h1>Add Epaper</h1>}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  initialValues={initialValues}
                  onSubmit={(values: any) => {
                    if (id) {
                      updateEpaper(values, onSuccess, onFailure);
                    } else {
                      createEpaper(values, onSuccess, onFailure);
                    }
                  }}
                  render={({ handleChange, values }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="col-md-12 p-2">
                            <Input
                              type="text"
                              name="title"
                              label="TITLE"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-12 p-2">
                            <Input
                              type="text"
                              name="seo.title"
                              label="SEO TITLE"
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="col-md-12 p-2">
                            <Input
                              type="text"
                              name="seo.description"
                              label="SEO DESCRIPTION"
                              onChange={handleChange}
                              required
                            />
                          </div>
                          
                          <div className="col-md-12 p-2">
                            <Input
                              name="seo.metaTags[0].content"
                              id="seo.metaTags[0].content"
                              label="Keywords"
                              maxLength="500"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6 p-2">
                            <FileUpload
                              accept={["application/pdf"]}
                              type="single"
                              maxSize={"30mb"}
                              name={"file"}
                              upload={uploadPublic}
                              assetUrl={ASSET_URL}
                              label="Upload PDF"
                              required
                            />
                          </div>
                          <div className="col-md-6 p-2">
                            <DateTime
                              name={`publishDate`}
                              id={`publishDate`}
                              label="Publish Date"
                              required
                              // minDate={new Date()}
                              dateFormat="yyyy-MM-dd hh:mm:ss"
                              placeholderText="Publish Date"
                              showTimeSelect={true}
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-end uvs">
                          <Button className="btn btn-success" type="submit">
                            <AiFillCheckCircle />
                            Submit
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default CreateEpaper;
