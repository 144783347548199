import React from "react";
import { Paginator, PaginatedTable } from "../../../Components";
import { SMALL_PAGE_SIZE } from "./../../../constant";
import {
  getBlogList,
  getCmsNotification,
  updateCms,
} from "./../../../Services/Service";
import { onFailure } from "./../../../util/helper";
import moment from "moment";
import { ActionInterface } from "../../../Components/BasicTable";
import {
  Edit_Article,
  Edit_Gallery_Article,
  Edit_Video_Article,
} from "./../../../RouteConstant";
import { useHistory } from "react-router-dom";
import { CmsStatus, PageType } from "../../../enums";
import Swal from "sweetalert2";
import FiterForm from "./FilterForm";

const TabData = ({ status, setNotifications }: any) => {
  const history = useHistory();

  const [data, setData] = React.useState({ list: [], total: 0 });
  const [currentPage, setPage] = React.useState(1);
  const [filters, setFilters] = React.useState({});
  let listParams: any = { populate: "categories_f=name, user_f=name" };
  if (status) {
    listParams.status = status;
  }
  
  const onSuccess = (data: any) => {
    setData(data.data);
  };

  React.useEffect(() => {
    getBlogList(onSuccess, onFailure, listParams);
  }, [status]);

  const headings = [
    {
      key: "title",
      text: "Title",
    },
    {
      key: "slug",
      text: "Url",
    },
    {
      key: "categories",
      text: "Categories",
    },
    {
      key: "user.name",
      text: "Author Name",
    },
    {
      key: "type",
      text: "Type",
    },
    {
      key: "status",
      text: "Status",
    },
    {
      key: "createdAt",
      text: "Created Date",
    },
    {
      key: "updatedAt",
      text: "Updated Date",
    },
    {
      key: "publishDate",
      text: "Publish Date/Time",
    },
  ];

  const formatters = {
    // categories: (data: any) => data.map((cat: any) => cat.name).join(","),
    createdAt: (data: any) => moment(data).format("DD/MM/YYYY hh:mm"),
    updatedAt: (data: any) => moment(data).format("DD/MM/YYYY hh:mm"),
    publishDate: (data: any) => moment(data).format("DD/MM/YYYY hh:mm"),
  };

  const pageChange = (page: any) => {
    getBlogList(onSuccess, onFailure, { page: page, ...listParams, ...filters });
    setPage(page);
  };

  const publishSuccess = (res: any) => {
    Swal.fire({
      title: "Blog Published Successfully",
      icon: "success",
    });
    getBlogList(onSuccess, onFailure, { page: currentPage, ...listParams });
  };

  let actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => {
        if (data.type === PageType.Article) {
          history.push(Edit_Article.replace(":id", data._id));
        } else if (data.type === PageType.Video) {
          history.push(Edit_Video_Article.replace(":id", data._id));
        } else if (data.type === PageType.Gallery) {
          history.push(Edit_Gallery_Article.replace(":id", data._id));
        }
      },
    },
  ];

  // const toDesk = {
  //   text: "Send to desk",
  //   trigger: (data: any) => {
  //     updateCms(
  //       {
  //         _id: data._id,
  //         status: CmsStatus.NeedReview,
  //         action: "desk",
  //       },
  //       publishSuccess,
  //       onFailure
  //     );
  //     getCmsNotification((data: any) => setNotifications(data.data), onFailure);
  //   },
  // };

  // const toEditor = {
  //   text: "Send to editor",
  //   trigger: (data: any) => {
  //     updateCms(
  //       {
  //         _id: data._id,
  //         status: CmsStatus.NeedReview,
  //         action: "editor",
  //       },
  //       publishSuccess,
  //       onFailure
  //     );
  //     getCmsNotification((data: any) => setNotifications(data.data), onFailure);
  //   },
  // };

  const commonAction = (title: string, action: CmsStatus) => ({
    text: title,
    trigger: (data: any) => {
      updateCms(
        {
          _id: data._id,
          status: action,
        },
        publishSuccess,
        onFailure
      );
      getCmsNotification((data: any) => setNotifications(data.data), onFailure);
    },
  });

  const actionByTab: any = {
    [CmsStatus.Draft]: [...actionItems],
    [CmsStatus.NeedReview]: [
      ...actionItems,
      // toDesk,
      // toEditor,
      commonAction("Publish", CmsStatus.Publish),
      commonAction("Approve", CmsStatus.Approved),
      commonAction("Reject", CmsStatus.Rejected),
    ],
    [CmsStatus.Approved]: [
      ...actionItems,
      commonAction("Publish", CmsStatus.Publish),
    ],
    [CmsStatus.Rejected]: [...actionItems],
    [CmsStatus.Publish]: [...actionItems],
    [CmsStatus.Pending]: [...actionItems],
    [CmsStatus.Retract]: [...actionItems],
    [CmsStatus.Scheduled]: [...actionItems],
  };

  return (
    <>
      <FiterForm status={status} setData={setData} listParams={listParams} setFilters={setFilters}/>
      <PaginatedTable
        headings={headings}
        data={data.list}
        totalSize={data.total}
        pageSize={SMALL_PAGE_SIZE}
        actionItems={actionByTab[status]}
        formatters={formatters}
        renderPager={() => (
          <Paginator
            totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
            onPageChange={(page) => pageChange(page)}
            page={currentPage}
          />
        )}
      />
    </>
  );
};

export default TabData;
