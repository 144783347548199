import styled from 'styled-components';

export const UploadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.9);
`;

export const LoadingRichText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.9);
`;

export const RichTextContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
`;
