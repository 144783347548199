import styled, { css } from "styled-components";
import { Table } from "react-bootstrap";
import partial from "lodash/partial";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { ActionDrawer } from "./ActionDrawer";
import React from "react";

export interface ActionInterface {
  text: string;
  trigger: any;
  conditionalText?: any;
}
export interface BasicTableInterface {
  title?: string;
  headings?: any;
  data?: any;
  className?: string;
  onClick?: () => void;
  displayHeader?: boolean;
  displayFooter?: boolean;
  showActionDrawer?: boolean;
  actionItems?: ActionInterface[];
  formatters?: any;
  footer?: any;
}

export interface TableData {
  key: string;
  text: string;
}
export interface BasicTableHeaderInterface {
  headings: TableData[];
  showActionDrawer: boolean;
}
export interface BasicTableFooterInterface {
  footer: any[];
}

export interface BasicTableRowInterface {
  dataItem: TableData;
  onClick?: (a: any) => void;
  index: number;
  headings: any;
  showActionDrawer: boolean;
  actionItems?: ActionInterface[];
  formatters?: any;
}

export const BasicTable = ({
  title,
  headings,
  data,
  className,
  onClick,
  displayHeader = true,
  displayFooter = false,
  showActionDrawer = true,
  footer,
  actionItems,
  formatters,
}: BasicTableInterface) => {
  if (!data || !headings) {
    return null;
  }

  return (
    <TableContainer className={className}>
      {title && <h1>{title}</h1>}
      <Table borderless>
        {displayHeader && (
          <TableHeader
            headings={headings}
            showActionDrawer={showActionDrawer}
          />
        )}
        <tbody>
          {data.map((dataItem: any, index: number) => (
            <TableRow
              key={index}
              onClick={onClick}
              dataItem={dataItem}
              index={index}
              headings={headings}
              showActionDrawer={showActionDrawer}
              actionItems={actionItems}
              formatters={formatters}
            />
          ))}
          {displayFooter && <TableFooter footer={footer} />}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export const TableHeader = ({
  headings,
  showActionDrawer,
}: BasicTableHeaderInterface) => (
  <thead>
    <tr>
      {headings.map((heading: TableData, i: number) => (
        <th key={heading.key + "-" + i}>{heading.text}</th>
      ))}
      {showActionDrawer && <th></th>}
    </tr>
  </thead>
);

export const TableFooter = ({ footer }: BasicTableFooterInterface) => (
  <FooterRow>
    {footer.map((data: any, i: number) => (
      <td key={"footer-" + i}>{data}</td>
    ))}
  </FooterRow>
);

export const TableRow = ({
  onClick,
  dataItem,
  index,
  headings,
  showActionDrawer,
  actionItems,
  formatters,
}: BasicTableRowInterface) => {
  return (
    <tr
      key={`table-row-${index}`}
      onClick={onClick && (() => onClick(dataItem))}
    >
      {headings.map(partial(renderCell, dataItem, formatters))}
      {showActionDrawer && (
        <td width="50">
          <ActionDrawer actionItems={actionItems} dataItem={dataItem} />
        </td>
      )}
    </tr>
  );
};

const renderCell = (
  dataItem: any,
  formatters: any,
  heading: any,
  idx: number
) => {
  const key = heading.key;
  let cellValue = get(dataItem, key);
  if (formatters) {
    const formatter = formatters[key];
    if (formatter) {
      cellValue = formatter(cellValue, dataItem);
    }
  }
  const content = isNil(cellValue) ? "-" : cellValue;
  return (
    <td key={`${key}_${idx}`} data-testid={`basic-table-cell-${key}-${idx}`}>
      {content}
    </td>
  );
};

export const TableContainer = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-grow: 2;
  h1 {
    font-size: ${(props) => props.theme.fsH3};
  }
`;

export default styled(BasicTable)`
  table {
    width: ${(props: any) => props.width || 100}%;

    tr {
      ${(props: any) =>
        props.columnWidths
          ? props.columnWidths.map((c: any) => {
              return css`
                th:nth-child(${c.index}) {
                  width: ${c.width};
                }
                td:nth-child(${c.index}) {
                  width: ${c.width};
                }
              `;
            })
          : null}
      ${(props: any) =>
        props.wrapText
          ? props.wrapText.map((w: any) => {
              return css`
                td:nth-child(${w.index}) {
                  word-wrap: break-word;
                  white-space: pre-wrap;
                  ${(isDesktop) =>
                    isDesktop &&
                    css`
                      @media only screen and (min-width: ${(props) =>
                          `${props.theme.breakpointMd}px`}) {
                        div {
                          display: inline-block;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                          min-width: 95%;
                          width: 200px;
                          max-width: 1000px;
                        }
                      }
                    `};
                }
              `;
            })
          : null}
        td {
        text-overflow: ellipsis;
      }
    }
  }
`;

const FooterRow = styled.tr`
  border-top: 1px solid #dee2e6;
`;
