import React from 'react';
import { InputProps } from './input.types';
import { useField, useFormikContext } from 'formik';
import { Label } from '../Label';
import { ErrorMessage } from '../ErrorMessage';

export const Input = ({
  id,
  label,
  required,
  name,
  placeholder,
  type = 'text',
  takeSpace,
  className,
  style,
  disabled,
  onChange,
  value,
  maxLength,
  accept
}: InputProps) => {
  const [field, meta] = useField({ name, type });
  const { submitCount } = useFormikContext();
  const showError = (submitCount > 0 || meta.touched) && meta.error;

  const input = (
    <input
      type={type}
      id={id || name}
      disabled={disabled}
      className={`form-control ${showError ? 'is-invalid' : ''}`}
      placeholder={placeholder}
      {...field}
      value={value ? value : field.value}
      onChange={onChange}
      maxLength={maxLength}
      accept={accept}
    />
  );

  return (
    <div className={className || 'form-group'} style={style}>
      <Label label={label} id={id} takeSpace={takeSpace} required={required} />
      {input}
      <ErrorMessage meta={meta} />
    </div>
  );
};
