import React from "react";
import styled from "styled-components";
// @ts-ignore
import UserIcon from "../public/image/UserIcon";
interface AvatarInterface {
  icon?: string;
  staticPath?: string;
}

export const Icon = styled.div`
  img,
  svg {
    width: 45px;
    height: 45px;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
  }
`;
export const Avatar = ({ icon, staticPath }: AvatarInterface) => {
  const url = staticPath ? `${staticPath}/${icon}` : icon;
  // console.log(url);
  return (
    <>
      <Icon>{icon ? <img src={url} alt="user-icon" /> : <UserIcon />}</Icon>
    </>
  );
};

export default Avatar;
