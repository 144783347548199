import React from 'react';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { Label } from './Label';
import { useField } from 'formik';
import { ErrorMessage } from './ErrorMessage';
import { CSSProperties } from 'react';

export type Option = { label: string; value: string };
export type OptionList = Option[];
export type OptionPromise = (input: string) => Promise<OptionList>;

export interface SelectProps {
  id: string;
  name: string;
  placeholder?: string;
  label?: string;
  className?: string;
  style?: CSSProperties;
  takeSpace?: boolean;
  required?: boolean;
  options: OptionList | OptionPromise;
  isMulti?: boolean;
  values?: string | string[];
  onChange?: (value: any) => void;
}

const colourStyles = {
  multiValue: (styles: any, {}: any) => {
    return {
      ...styles,
      backgroundColor: '#0d6efd',
    };
  },
  multiValueLabel: (styles: any, {}: any) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles: any, {}: any) => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#0d6efd',
      color: 'white',
    },
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 99,
  }),
  control: (base: any, state: any) => ({
    ...base,
    border: '1px solid #ced4da',
    boxShadow: 'none',
    // #239f87
    borderColor: state.isFocused ? '#0d6efd' : '#0d6efd',
    '&:focus': {
      borderColor: state.isFocused ? '#0d6efd' : '#ced4da',
    },
    '&:hover': {
      borderColor: state.isFocused ? '#0d6efd' : '#ced4da',
      boxShadow: 'none',
    },
  }),
  option: (styles: any, { isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? null
        : isFocused
        ? '#0d6efd'
        : null,
      color: isDisabled ? null : isSelected ? null : isFocused ? '#fff' : null,
      ':active': {
        ...styles[':active'],
        backgroundColor: '#0d6efd',
      },
    };
  },
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      color: '#919191',
    };
  },
};

export const Select = ({
  id,
  name,
  label,
  className,
  style,
  takeSpace,
  required,
  placeholder,
  options,
  isMulti,
  values,
  onChange,
}: SelectProps) => {
  const [field, meta, helpers] = useField({ name });
  const _onChange = (v: any) => {
    if (isMulti) {
      helpers.setValue(v.map((item: any) => item.value));
    } else {
      helpers.setValue(v.value);
    }
    onChange && onChange(v);
  };

  const getValue = () => {
    return Array.isArray(options)
      ? options.filter(({ value }) => {
          if (isMulti) {
            return (values || field.value || []).includes(value);
          } else {
            return (values && values === value) || field.value === value;
          }
        })
      : null;
  };

  return (
    <div className={className || 'form-group'} style={style}>
      <Label label={label} id={id} takeSpace={takeSpace} required={required} />
      {Array.isArray(options) ? (
        <div className="custom-ReactSelect">
          <ReactSelect
            value={getValue()}
            onChange={_onChange}
            options={options}
            placeholder={placeholder}
            isMulti={isMulti}
            styles={colourStyles}
          />
        </div>
      ) : (
        <div className="custom-AsyncSelect">
          <AsyncSelect
            cacheOptions
            defaultOptions
            onChange={_onChange}
            loadOptions={options}
            placeholder={placeholder}
            isMulti={isMulti}
            styles={colourStyles}
          />
        </div>
      )}
      <ErrorMessage meta={meta} />
    </div>
  );
};
