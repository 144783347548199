import { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { CmsStatus } from "../../../enums";
import {
  Create_Article,
  // Create_Gallery_Article,
  // Create_Video_Article,
} from "../../../RouteConstant";
import { getCmsNotification } from "../../../Services/Service";
import TabData from "./TabData";
import { onFailure } from "./../../../util/helper";
// import get from "lodash/get";
// import { Link } from "react-router-dom";

// const TabTitle = ({ title, count }: any) => {
//   return (
//     <>
//       {title}
//       {!!count && count > 0 && (
//         <Badge className="ms-1" bg="secondary">
//           {count}
//         </Badge>
//       )}
//     </>
//   );
// };
export const BlogManagementPage = () => {
  const [key, setKey] = useState<string>("all");

  const [notification, setNotifications] = useState({});

  const onSuccess = (data: any) => {
    setNotifications(data.data);
  };

  useEffect(() => {
    getCmsNotification(onSuccess, onFailure);
  }, []);

  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Blog Management</h1>
          </div>
          <a href={Create_Article} type="button" className="btn-success">Create Blog</a>
          {/* <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={Create_Article}>
                Article
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={Create_Video_Article}>
                Video
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={Create_Gallery_Article}>
                Gallery
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <Button
            onClick={() => history.push(Create_Article)}
            className="btn btn-success"
          >
            Add Article
          </Button> */}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k: any) => setKey(k)}
                  className="mb-3"
                  mountOnEnter={true}
                  unmountOnExit={true}
                >
                  <Tab eventKey="all" title="All">
                    <TabData setNotifications={setNotifications} />
                  </Tab>
                  <Tab eventKey="draft" title="Draft">
                    <TabData
                      status={CmsStatus.Draft}
                      setNotifications={setNotifications}
                    />
                  </Tab>
                 
                  {/* <Tab eventKey="approved" title="Approved">
                    <TabData
                      status={CmsStatus.Approved}
                      setNotifications={setNotifications}
                    />
                  </Tab> */}
                  <Tab eventKey="Published" title="Published">
                    <TabData
                      status={CmsStatus.Publish}
                      setNotifications={setNotifications}
                    />
                  </Tab>
                  {/* <Tab
                    eventKey="needReview"
                    title={
                      <TabTitle
                        title="Need Review"
                        count={get(notification, "Need Review")}
                      />
                    }
                  >
                    <TabData
                      status={CmsStatus.NeedReview}
                      setNotifications={setNotifications}
                    />
                  </Tab> */}
                  {/* <Tab eventKey="rejected" title="Rejected">
                    <TabData
                      status={CmsStatus.Rejected}
                      setNotifications={setNotifications}
                    />
                  </Tab> */}
                  {/* <Tab eventKey="retracted" title="Retract">
                    <TabData
                      status={CmsStatus.Retract}
                      setNotifications={setNotifications}
                    />
                  </Tab>
                 
                  <Tab eventKey="scheduled" title="Scheduled">
                    <TabData
                      status={CmsStatus.Scheduled}
                      setNotifications={setNotifications}
                    />
                  </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
// export default BlogManagementPage;
