import { useState } from "react";
import { useEffect } from "react";
import { getBlogList, getCategoryList, getPopulatedCategoryList } from "./../../../Services/Service";
import { onFailure } from "./../../../util/helper";
import { Input, Form, ImageFromAsset } from "./../../../Components";
import { Button, ListGroup, Table } from "react-bootstrap";
import ReactSelect from "react-select";
import { Paginator } from "./../../../Components/Paginator";
import { SMALL_PAGE_SIZE } from "../../../constant";
import { FaPlus } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";

const ArticleSelector = ({
  onSave,
  type,
  data,
  activeCategory,
  pageType,
}: any) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  // const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [category, setCategory] = useState<any>();
  const [articleList, setArticleList] = useState({ list: [], total: 0 });
  const [selectedArticle, setSelectedArticle] = useState<any>([]);
  const [queryParam, setQueryParam] = useState<any>({categories:[activeCategory ? activeCategory._id : '']});
  const [currentPage, setPage] = useState(1);
  useEffect(() => {
    getPopulatedCategoryList(categorySuccess, onFailure);
  }, []);

  useEffect(() => {
    if (data) {
      setSelectedArticle(data);
    }
  }, [data]);
  useEffect(() => {
    if (activeCategory) {
      setCategory(activeCategory);
    }
  }, [activeCategory]);

  const pageChange = (page: any) => {
    setQueryParam({ ...queryParam, page });
    setPage(page);
  };

  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: `${(category.parent && category.parent != null) ? `${category.parent[0]?.name} |` :'' }  ${category.name}`,
      value: category._id,
    }));
    setCategoryOptions(options);
  };

  const onSuccess = ({ data }: any) => {
    setArticleList(data);
  };

  useEffect(() => {
    if (Object.keys(queryParam).length > 0) {
      getBlogList(onSuccess, onFailure, {
        ...queryParam,
        type: type,
      });
    }
    // if (activeCategory) {
    //   getSubCategory(activeCategory._id);
    // }
  }, [queryParam]);

  const removeArticle = (id: any) => {
    const newList = selectedArticle.filter(
      (article: any) => article._id !== id
    );
    setSelectedArticle(newList);
  };

  // const onSuccessSubCategory = ({ data }: any) => {
  //   const options = data.map((category: any) => ({
  //     label: category.name,
  //     value: category._id,
  //   }));
  //   setSubCategoryOptions(options);
  // };
  // const getSubCategory = (catId: string) => {
  //   getCategoryList(onSuccessSubCategory, onFailure, { parent: catId });
  // };
  return (
    <>
      <div className="row">
        {/* <div className="col-md-3">
          <div className="custom-ReactSelect">
            
          </div>
        </div> */}
        {/* <div className="col-md-3">
          <ReactSelect
            onChange={(v: any) => {
              setQueryParam({ categories: [v.value] });
            }}
            options={subCategoryOptions}
            placeholder={"Select Category"}
          />
        </div> */}
      </div>
      <Table className="mt-4" bordered responsive>
        <tr>
          <td width={"50%"}>
            <ReactSelect
              onChange={(v: any) => {
                if(!activeCategory){
                  setCategory({ _id: v.value, name: `${v.label.split('|')[1] ? `${v.label.split('|')[1]}` :`${v.label}`} ` });
                  
                }
                setQueryParam({categories:[v.value]});
                // getSubCategory(v.value);
              }}
              defaultValue={{
                label: activeCategory?.name,
                value: activeCategory?._id,
              }}
              options={categoryOptions}
              placeholder={"Select Category"}
            />
            {/* <ReactSelect
              onChange={(v: any) => {
                setQueryParam({
                  categories: [v.value],
                });
              }}
              options={subCategoryOptions}
              placeholder={"Select Sub Category"}
            /> */}
            <Form
              initialValues={{ search: "" }}
              onSubmit={(values: any) => {
                getBlogList(onSuccess, onFailure, {
                  ...queryParam,
                  type: type,
                  ...values,
                });
              }}
              render={({ handleChange }: any) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          type="text"
                          name="search"
                          placeholder="Search Article"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-8">
                        <Input
                          type="date"
                          name="date"
                          // placeholder="Search Article"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <Button className="btn btn-success" type="submit">
                          Search
                        </Button>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          </td>
          <td>
            <h4>Selected Articles</h4>
          </td>
        </tr>
        <tr>
          <td>
            <ListGroup>
              {articleList.list.map((article: any) => (
                <ListGroup.Item>
                  <div className="float-end">
                    {pageType === "top_headline" && (
                      <Button
                        className="btn btn-sm"
                        disabled={selectedArticle.length > 3}
                        onClick={() =>
                          setSelectedArticle([...selectedArticle, article])
                        }
                      >
                        <FaPlus />
                      </Button>
                    )}
                    {pageType === "top_story" && (
                      <Button
                        className="btn btn-sm"
                        disabled={selectedArticle.length > 5}
                        onClick={() =>
                          setSelectedArticle([...selectedArticle, article])
                        }
                      >
                        <FaPlus />
                      </Button>
                    )}
                    {pageType === "section" && (
                      <Button
                        className="btn btn-sm"
                        onClick={() =>
                          setSelectedArticle([...selectedArticle, article])
                        }
                      >
                        <FaPlus />
                      </Button>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <ImageFromAsset id={article.image} />
                    </div>
                    <div className="col-9">
                      <h6>{article.title}</h6>
                      <p>{article.description}</p>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Paginator
              totalPages={Math.ceil(articleList.total / SMALL_PAGE_SIZE)}
              onPageChange={(page) => pageChange(page)}
              page={currentPage}
            />
          </td>
          <td>
            {selectedArticle.map((article: any) => (
              <ListGroup>
                <ListGroup.Item>
                  <div className="float-end">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => removeArticle(article._id)}
                    >
                      <AiFillDelete />
                    </Button>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <ImageFromAsset id={article.image} />
                    </div>
                    <div className="col-9">
                      <h6>{article.title}</h6>
                      <p>{article.description}</p>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))}
          </td>
        </tr>
      </Table>
      <div className="d-flex justify-content-end">
        <Button
          className="btn btn-dark"
          onClick={() => onSave({ selectedArticle, category })}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default ArticleSelector;
