import { PaginatedTable, Paginator } from "../../Components";
import { ActionInterface } from "../../Components/BasicTable";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SMALL_PAGE_SIZE } from "../../constant";
import { getRedirectUrlList } from "../../Services/Service";
import { Create_Redirect_url, Edit_Redirect_url } from "./../../RouteConstant";
import { onFailure } from "./../../util/helper";

const ManageUrlPage = () => {
  const [data, setData] = React.useState({ list: [], total: 0 });
  const history = useHistory();
  const [currentPage, setPage] = useState(1);
  const onSuccess = (data: any) => {
    setData(data.data);
  };

  React.useEffect(() => {
    getRedirectUrlList(onSuccess, onFailure);
  }, []);

  const headings = [
    {
      key: "oldUrl",
      text: "Old Url",
    },
    {
      key: "redirectUrl",
      text: "Redirect Url",
    },
    {
      key: "status",
      text: "Status",
    },
  ];

  const pageChange = (page: any) => {
    getRedirectUrlList(onSuccess, onFailure, { page: page });
    setPage(page);
  };

  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => history.push(Edit_Redirect_url.replace(":id", data._id)),
    },
    // {
    //   text: "Block",
    //   trigger: (data: any) => console.log(data),
    // },
  ];
  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Manage Redirect Url</h1>
          </div>
          <Button
            onClick={() => history.push(Create_Redirect_url)}
            className="btn btn-success"
          >
            Add Redirect Url
          </Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <PaginatedTable
                  headings={headings}
                  data={data.list}
                  totalSize={data.total}
                  pageSize={SMALL_PAGE_SIZE}
                  // showActionDrawer={false}
                  actionItems={actionItems}
                  renderPager={() => (
                    <Paginator
                      totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                      onPageChange={(page) => pageChange(page)}
                      page={currentPage}
                    />
                  )}
                ></PaginatedTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default ManageUrlPage;
