import { MdAttachFile } from 'react-icons/md';
import React from 'react';
import { IFileToUpload } from './file-uploader.types';
import { UploadingContainer } from './file-upload.styled';
import { truncateFileName } from './utils';

export const FileUploading = ({ file }: { file: IFileToUpload }) => {
  return (
    <UploadingContainer>
      <MdAttachFile className={'mr-12pt'} />
      <div className={'flex  mr-12pt'}>
        <div className="card-title">{truncateFileName(file.name, 8)}</div>
        <p className="flex text-black-50 lh-1 mb-0">
          <small>
            {file.isUploading ? `${file.uploadPercent}%` : 'Uploaded.'}
          </small>
        </p>
      </div>
      {file.isUploading && <span className="loader loader-sm"></span>}
    </UploadingContainer>
  );
};
