import React from "react";
import DatePicker from "react-datepicker";

import { useField } from "formik";
import "react-datepicker/dist/react-datepicker.min.css";
import styled from "styled-components";
import { FaCalendarAlt } from "react-icons/fa";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";

import { ReactDatePickerProps } from "react-datepicker";

export interface DateTimeProps extends Omit<ReactDatePickerProps, "onChange"> {
  label?: string;
  takeSpace?: boolean;
  required?: boolean;
  minDate?: Date;
  autoComplete?: string;
  onChange?: (date: Date | null) => {};
}

const Wrapper = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }
`;

export const DateTime = ({
  name,
  id,
  className,
  onChange,
  label,
  required,
  takeSpace,
  minDate,
  ...props
}: DateTimeProps) => {
  const [field, meta, helpers] = useField({ name: name || "date" });
  const _id = id || name;
  const handleChange = (date: Date | null) => {
    helpers.setValue(date);
    onChange && onChange(date);
  };

  return (
    <Wrapper className={className || "form-group"}>
      <Label label={label} id={id} takeSpace={takeSpace} required={required} />
      <div className="datepicker-wrap">
        <DatePicker
          id={_id}
          {...field}
          {...props}
          className={"form-control"}
          selected={meta.value ? new Date(meta.value) : meta.value}
          onChange={handleChange}
          minDate={minDate}
          autoComplete="off"
        />
        <FaCalendarAlt />
      </div>
      <ErrorMessage meta={meta} />
    </Wrapper>
  );
};
