import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SMALL_PAGE_SIZE } from "../../../constant";
import { Create_Ad, Edit_Ad } from "../../../RouteConstant";
import { getAdvertisementList, getCategoryList } from "../../../Services/Service";
import { onFailure } from "../../../util/helper";
import { Form, Input, PaginatedTable, Paginator, Select } from "../../../Components";
import { ActionInterface } from "../../../Components/BasicTable";
import moment from "moment";
import { AiFillFilter } from "react-icons/ai";

const MangageAdvertisement = () => {
  const [data, setData] = React.useState({ list: [], total: 0 });
  const history = useHistory();
  const [currentPage, setPage] = useState(1);
  const defaultFormValues = {
    search: "",
    categories: [],
    date:''
  };
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [showFilter, setShowFilter] = useState(false);
  const [categoryOption, setCategoryOptions] = useState([]);
  const listParams = {
    populate: "user_f=name, client_f=name, categories_f=name",
  };

  const onSuccess = (data: any) => {
    setData(data.data);
  };

  React.useEffect(() => {
    getAdvertisementList(onSuccess, onFailure, listParams);
    getCategoryList(categorySuccess, onFailure);
  }, []);

  const headings = [
    {
      key: "title",
      text: "Title",
    },
    {
      key: "description",
      text: "Description",
    },
    {
      key: "url",
      text: "URL",
    },
    {
      key: "categories",
      text: "Categories",
    },
    {
      key: "startDate",
      text: "Start Date",
    },
    {
      key: "endDate",
      text: "End Date",
    },
    {
      key: "client.name",
      text: "Client",
    },
    {
      key: "isFeatured",
      text: "Show in Home",
    },
    {
      key: "position",
      text: "Position",
    },
    {
      key: "maxImpressionAllowed",
      text: "Impression Allowed",
    },
    {
      key: "views",
      text: "Views Received",
    },
    {
      key: "clicks",
      text: "Clicks Received",
    },
    {
      key: "user.name",
      text: "Added By",
    },
  ];

  const formatters = {
    categories: (data: any) => data.map((cat: any) => cat.name).join(","),
    startDate: (data: any) => moment(data).format("DD/MM/YYYY HH:SS"),
    endDate: (data: any) => moment(data).format("DD/MM/YYYY HH:SS"),
  };

  const pageChange = (page: any) => {
    getAdvertisementList(onSuccess, onFailure, { ...listParams, page: page });
    setPage(page);
  };
  const search = (query: any) => {
    getAdvertisementList(onSuccess, onFailure, { ...listParams, ...query });
  };
  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: category.name,
      value: category._id,
    }));
    setCategoryOptions(options);
  };

  

 
  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => history.push(Edit_Ad.replace(":id", data._id)),
    },
  ];
  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Advertisement Management</h1>
          </div>
          <Button
            onClick={() => history.push(Create_Ad)}
            className="btn btn-success"
          >
            Add Advertisement
          </Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body table-responsive">
              <div className="d-flex justify-content-end">
        <Button
          variant="outline-info"
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        >
          <AiFillFilter />
        </Button>
      </div>
      {showFilter && (
        <Form
          initialValues={initialValues}
          onSubmit={(values: any) => {
            // console.log(values);
            search(values);
          }}
          render={({ handleChange, resetForm, submitForm }: any) => {
            return (
              <div className="row">
                <div className="col-md-3 p-2">
                  <Input
                    type="text"
                    name="search"
                    // label="TITLE"
                    placeholder="Enter Text to search"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 p-2">
                  <Select
                    isMulti={true}
                    name="categories"
                    id="categories"
                    options={categoryOption}
                    placeholder="Select Category"
                  />
                </div>
                <div className="col-md-3 p-2">
                <Input
                    type="date"
                    name="date"
                    // label="TITLE"
                    // placeholder="Enter Text to search"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 p-2">
                  <ButtonGroup>
                    <Button className="btn btn-success" type="submit">
                      Apply
                    </Button>
                    <Button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={() => {
                        resetForm();
                        submitForm();
                      }}
                    >
                      Reset
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            );
          }}
        />
      )}
                <PaginatedTable
                  headings={headings}
                  data={data.list}
                  totalSize={data.total}
                  pageSize={SMALL_PAGE_SIZE}
                  actionItems={actionItems}
                  formatters={formatters}
                  renderPager={() => (
                    <Paginator
                      totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                      onPageChange={(page) => pageChange(page)}
                      page={currentPage}
                    />
                  )}
                ></PaginatedTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default MangageAdvertisement;
