import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  getBreakingNewsList,
  updateBreakingNews,
} from "../../Services/Service";
import { onFailure } from "../../util/helper";
import AddBreakingNewsModal from "./CreateBreakingNews";
import EditBreakingNewsPage from "./EditBreakingNews";
import { PaginatedTable, Paginator } from "../../Components";
import { ActionInterface } from "../../Components/BasicTable";
import { SMALL_PAGE_SIZE } from "../../constant";
import Swal from "sweetalert2";

const ManageBreakingNewsPage = () => {
  const [data, setData] = React.useState<any>([]);
  const [openModel, setopenModel] = useState(false);
  const [openEditModel, setopenEditModel] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [currentPage, setPage] = useState(1);
  const onSuccess = (data: any) => {
    setData(data.data);
  };
  React.useEffect(() => {
    getBreakingNewsList(onSuccess, onFailure);
  }, [openModel,openEditModel]);
  const headings = [
    {
      key: "title",
      text: "Headline",
    },
    {
      key: "url",
      text: "URL",
    },
    {
      key: "status",
      text: "Status",
    },
  ];
  const handleCreateModelClose = () => {
    setopenModel(false);
  };
  const handleEditModelClose = () => {
    setopenEditModel(false);
  };
  const handleOpenEditModel = (data: any) => {
    setopenEditModel(true);
    setEditValue(data);
  };
  const handleCreateModelOpen = () => {
    setopenModel(true);
  };

  const pageChange = (page: any) => {
    getBreakingNewsList(onSuccess, onFailure, { page: page });
    setPage(page);
  };
  const publishSuccess = (res: any) => {
    Swal.fire({
      title: "Breaking News updated Successfully",
      icon: "success",
    });
    getBreakingNewsList(onSuccess, onFailure);
  };
  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => handleOpenEditModel(data),
    },
    {
      text: "Inactive",
      trigger: (data: any) =>
        updateBreakingNews(
          {
            _id: data._id,
            status: data.status === "Enable" ? "Disable" : "Enable",
          },
          publishSuccess,
          onFailure
        ),
      conditionalText: (data: any) =>
        data.status === "Enable" ? "Inactive" : "Active",
    },
  ];
  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Manage Category</h1>
          </div>
          <Button className="btn btn-success" onClick={handleCreateModelOpen}>
            Add Category
          </Button>
        </div>

        <div style={{}}>
          <PaginatedTable
            headings={headings}
            data={data.list}
            totalSize={data.total}
            pageSize={SMALL_PAGE_SIZE}
            actionItems={actionItems}
            //   formatters={formatters}
            renderPager={() => (
              <Paginator
                totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                onPageChange={(page) => pageChange(page)}
                page={currentPage}
              />
            )}
          ></PaginatedTable>
        </div>
      </main>
      {openModel && (
        <AddBreakingNewsModal
          show={openModel}
          setopenModel={setopenModel}
          handleClose={handleCreateModelClose}
        />
      )}
      {openEditModel && (
        <EditBreakingNewsPage
          show={openEditModel}
          setopenModel={setopenEditModel}
          editValue={editValue}
          handleClose={handleEditModelClose}
        />
      )}
    </>
  );
};
export default ManageBreakingNewsPage;
