import {
  FaTachometerAlt,
  FaUserAlt,
  FaShoppingBasket,
  FaListAlt,
  FaNewspaper,
  FaPaperclip,
  // FaTablet,
  FaUniversalAccess,
  FaLink,
} from "react-icons/fa";
import { BsFillExclamationOctagonFill } from "react-icons/bs";
import { MenuInterface, Sidebar } from "./Sidebar";
import {
  Article_Management,
  Dashboard,
  // Epaper_Management,
  Manage_Category,
  Manage_Redirect_url,
  Manage_Role,
  Manage_User,
  Menu_Management,
  // Home_Top_Headline,
  // Home_Top_Story,
  // Home_Manage_Section,
  // Home_Cover_Story,
  // Client_Management,
  // Ad_Management,
  // Manage_Breaking_News,
  Site_Management,
} from "../RouteConstant";

const menuItem: MenuInterface[] = [
  {
    path: Dashboard,
    title: "Dashboard",
    icon: <FaTachometerAlt className="app-menu__icon" />,
  },
  {
    path: "#",
    title: "Blog Management",
    icon: <FaNewspaper className="app-menu__icon" />,
    subMenu: [
      {
        path: Article_Management,
        title: "Manage Blog",
        icon: <FaPaperclip className="app-menu__icon" />,
      },
      // {
      //   path: Epaper_Management,
      //   title: "Manage Epaper",
      //   icon: <FaTablet className="app-menu__icon" />,
      // },
    ],
  },
  {
    path: "#",
    title: "Access Management",
    icon: <FaUniversalAccess className="app-menu__icon" />,
    subMenu: [
      {
        path: Manage_Role,
        title: "Role Managememt",
        icon: <BsFillExclamationOctagonFill className="app-menu__icon" />,
      },
      {
        path: Manage_User,
        title: "User Managememt",
        icon: <FaUserAlt className="app-menu__icon" />,
      },
    ],
  },
  {
    path: Manage_Category,
    title: "Manage Categories",
    icon: <FaShoppingBasket className="app-menu__icon" />,
  },
  {
    path: "#",
    title: "Appearance",
    icon: <FaNewspaper className="app-menu__icon" />,
    subMenu: [
      {
        path: Menu_Management,
        title: "Menu Management",
        icon: <FaListAlt className="app-menu__icon" />,
      },
    ],
  },
  {
    path: Manage_Redirect_url,
    title: "Manage Redirect Url",
    icon: <FaLink className="app-menu__icon" />,
  },
  // {
  //   path: Page_Management,
  //   title: "No-Seo Page Management",
  //   icon: <FaPager className="app-menu__icon" />,
  // },

  // {
  //   path: "#",
  //   title: "Ad Management",
  //   icon: <FaUniversalAccess className="app-menu__icon" />,
  //   subMenu: [
  //     {
  //       path: Ad_Management,
  //       title: "Manage Adds",
  //       icon: <BsFillExclamationOctagonFill className="app-menu__icon" />,
  //     },
  //     {
  //       path: Client_Management,
  //       title: "Manage Clients",
  //       icon: <FaUserAlt className="app-menu__icon" />,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   title: "Manage Home Page",
  //   icon: <FaUniversalAccess className="app-menu__icon" />,
  //   subMenu: [
  //     {
  //       path: Home_Top_Headline,
  //       title: "Top Headlines",
  //       icon: <BsFillExclamationOctagonFill className="app-menu__icon" />,
  //     },
  //     {
  //       path: Home_Top_Story,
  //       title: "Top Story of the day",
  //       icon: <FaUserAlt className="app-menu__icon" />,
  //     },
  //     {
  //       path: Home_Cover_Story,
  //       title: "Cover Story",
  //       icon: <FaUserAlt className="app-menu__icon" />,
  //     },
  //     {
  //       path: Home_Manage_Section,
  //       title: "Manage Sections",
  //       icon: <FaUserAlt className="app-menu__icon" />,
  //     },
  //   ],
  // },
  // {
  //   path:Manage_Breaking_News,
  //   title:"Manage Breaking News",
  //   icon:<FaUniversalAccess className="app-menu__icon" />,
  // },
  {
    path: Site_Management,
    title: "Site Management",
    icon: <FaUniversalAccess className="app-menu__icon" />,
  },
];

export const Menu = () => <Sidebar menuItems={menuItem} />;
